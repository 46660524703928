import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import("@/pages/LoginPage.vue")
  },
  {
    path: '/layout',
    name: 'layout',
    component: () => import('@/pages/MainPage.vue'),
    children: [
      {
        path: '/users',
        name: 'users',
        component: () => import('@/pages/UsersPage.vue'),
        meta: { section: 'users' }
      },
      {
        path: '/users/:id',
        name: 'user-details',
        component: () => import('@/pages/UserDetailsPage.vue'),
        meta: { section: 'users' },
        children: [
          {
            path: 'history',
            name: 'history',
            component: () => import('@/pages/PlayerEventRecordsPage.vue'),
            meta: { section: 'users' }
          },
        ]
      },
      {
        path: '/reports',
        name: 'reports',
        component: () => import('@/pages/ReportsPage.vue'),
        meta: { section: 'reports' }
      },
      {
        path: '/courses',
        name: 'courses',
        component: () => import('@/pages/CoursesPage.vue'),
        meta: { section: 'courses' }
      },
      {
        path: '/courses/:id',
        name: 'courses-details',
        component: () => import('@/pages/CoursesDetailsPage.vue'),
        meta: { section: 'courses' }
      },
      {
        path: '/create-course/',
        name: 'create-courses',
        component: () => import('@/pages/CourseCreatePage.vue'),
        meta: { section: 'courses' }
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: () => import('@/pages/NotificationsPage.vue'),
        meta: { section:'notifications' }
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
