import { createApp } from 'vue'
import App from './App.vue'
import Flare from "@lkmx/flare";
import FlareVue from "@lkmx/flare-vue";
import router from './router';
import { createPinia } from 'pinia';

import '@/styles/_colors.scss'
import '@/styles/_reports-tables.scss'
import '@fontsource/oswald/700.css';
import '@fontsource/roboto';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

createApp(App)
    .use(Flare)
    .use(FlareVue)
    .use(router)
    .use(createPinia())
    .mount('#app');